import { SettingsPage as SettingsSection, Slider, TitledSection } from "@ivaoaero/front-components";
import { useCallback, useMemo, VFC } from "react";
import { useUser } from "../../../auth";
import { useSettings } from "../../hooks/useSettings";
import { useUpdateSettings } from "../../hooks/useUpdateSettings";
import SliderPicture from "../../../common/img/slider.png";
import "./index.module.scss";
import mapsArray from "../../../common/utils/maps.json";
import { mapsMigration } from "../../hooks/useMapLayer";

// @ts-ignore
const maps = mapsArray.reduce((acc, map) => {
  return {
    ...acc,
    [map.id]: { label: map.name, value: map.id },
  };
}, {});

const interval = {
  1: { label: '15 secs', value: 1 },
  2: { label: '30 secs', value: 2 },
  4: { label: '1 minute', value: 4 },
  6: { label: '1.5 minute', value: 6 },
  8: { label: '2 minute', value: 8 },
  10: { label: '2.5 minute', value: 10 },
  12: { label: '3 minute', value: 12 },
}

export const SettingsPage: VFC = () => {
  const user = useUser();
  const settings = useSettings();
  const updateSettings = useUpdateSettings();

  const callback = useCallback((key: string, environment: string, value: any) => updateSettings.mutate({ environment, key, value }), [updateSettings])
  const fullName = useMemo(() => `${user.user.firstName} ${user.user.lastName}`, [user.user])

  const settingsOptions = [
    {
      title: "User Interface",
      callback,
      items: [
        {
          name: 'Background Map',
          type: 'select',
          key: 'map_layer',
          // @ts-ignore
          value: maps[settings.data?.map_layer ?? 'voyager'] ?? maps[mapsMigration[settings.data?.map_layer ?? 'Voyager']] ?? maps['voyager'],
          options: Object.values(maps)
        },
        { name: 'Dark Mode', type: 'boolean', key: 'dark_mode', value: settings.data?.dark_mode, },
        {
          name: 'Tracker Interval', type: 'select', environment: 'trSettings', key: 'tracks_interval', 
// @ts-ignore
          value: interval[settings.data?.tracks_interval ?? 1],
          options: Object.values(interval)
        }
      ],
    },
    {
      title: "Privacy",
      callback,
      items: [
        { name: 'Show your name to non-IVAO visitors', type: 'boolean', key: 'name_public', value: settings.data?.name_public },
        {
          name: "Logout from all IVAO websites when logging out of Tracker",
          type: "boolean",
          key: "sso_logout",
          value: settings.data?.sso_logout,
        },
      ],
    },
  ]

  return (
    <>
      <Slider title="Settings" subtitle={user.isSuccess ? fullName : `Loading`} backgroundImage={SliderPicture} />
      <TitledSection>
        <div style={{ marginLeft: -15, marginRight: -15 }}>
          <SettingsSection items={settingsOptions} />
        </div>
      </TitledSection>
    </>
  );
};
